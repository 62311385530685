const apiDomain = import.meta.env.VITE_BASE_API;

const uploadDomain = '/property/common/upload'; // 腾讯云

const imgDomain = import.meta.env.VITE_TX_COS_URL; // 图片域名，切换腾讯云后，上传返回长地址，无需拼接
const domainPrefix = 'cms/';

// if (import.meta.env.VITE_NODE_ENV === 'development') {

// }

export default {
    devApiDomain: apiDomain + domainPrefix,
    uploadDomain,
    imgDomain,
};
