import {
  UPDATE_USER_INFO,
  SET_BUCKET_DOMAIN,
  SET_BUCKET_DOMAIN_TOKEN,
  SET_REGION_DATA,
  SET_REGION_CITY,
  SET_KEEP_ALIVE,
} from './mutation-types';

export default {
  [UPDATE_USER_INFO](state, info) {
    state.userInfo = info
  },
  [SET_BUCKET_DOMAIN](state, payload) {
    state.bucket_domain = payload;
  },
  [SET_BUCKET_DOMAIN_TOKEN](state, payload) {
    state.bucket_domain_token = payload;
  },
  [SET_REGION_DATA](state, payload) {
    state.regionData = payload;
  },
  [SET_REGION_CITY](state, payload) {
    state.regionCity = payload;
  },
  [SET_KEEP_ALIVE](state, payload) {
    state.isKeepAlive = payload;
  },

}
