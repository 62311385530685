const Tenantlist = () => import('@building/vuepage/history-tenant/tenant-list.vue');
const Stafflist = () => import('@building/vuepage/history-tenant/staff-list.vue');
const StaffInfo = () => import('@building/vuepage/history-tenant/staff-info.vue');
const TenantInfo = () => import('@building/vuepage/history-tenant/tenant-info.vue');

export default [
  {
    path: '/history-tenant/tenant-list',
    component: Tenantlist,
    meta: {
      tar: [
        { name: '历史企业管理', url: '' },
        { name: '历史企业列表', url: '/history-tenant/tenant-list' },
      ],
    },
  },
  {
    path: '/history-tenant/tenant-list/staff-list',
    component: Stafflist,
    meta: {
      tar: [
        { name: '历史企业管理', url: '' },
        { name: '历史企业列表', url: '/history-tenant/tenant-list' },
        { name: '员工列表', url: '' },
      ],
    },
  },
  {
    path: '/history-tenant/tenant-list/staff-list/info',
    component: StaffInfo,
    meta: {
      tar: [
        { name: '历史企业管理', url: '' },
        { name: '历史企业列表', url: '/history-tenant/tenant-list' },
        { name: '员工列表', url: '/history-tenant/tenant-list/staff-list' },
        { name: '员工详情', url: '' },
      ],
    },
  },
  {
    path: '/history-tenant/tenant-list/tenant-info',
    component: TenantInfo,
    meta: {
      tar: [
        { name: '历史企业管理', url: '' },
        { name: '历史企业列表', url: '/history-tenant/tenant-list' },
        { name: '企业详情', url: '' },
      ],
    },
  },
]
