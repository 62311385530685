// 公共资源接口
// 2019-01-18 08:27:15

import HttpRequest from '@building/jslib/dk-axios';
import env from '@building/configs/environment';

// 上传
export const UploadFile = data =>
    HttpRequest({
        url: env.uploadDomain,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
        timeout: 40000,
    });
