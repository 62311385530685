const GoodsTravel = () => import('@building/vuepage/com-examine-manage/goods-travel.vue');
const MonthCard = () => import('@building/vuepage/com-examine-manage/month-card.vue');

export default [
  {
    path: '/com-examine-manage/goods-travel',
    component: GoodsTravel,
  },
  {
    path: '/com-examine-manage/month-card',
    component: MonthCard,
  },
]
