import DkAxios from '@building/jslib/dk-axios'

// 停车管理
// 停车列表
/* eslint-disable */
export const parkingList = params => DkAxios({
  url: 'api/pay/GetCarInOutInfoByPlaceMap',
  method: 'GET',
  params,
})

// 充电桩保修列表
export const getCharingList = data => DkAxios({
  url: 'property/parking/chargingProblemList',
  method: 'GET',
  params:data,
})

// 充电桩保修列表导出
export const getCharingListExport = data => DkAxios({
  url: 'property/parking/chargingProblemList/export',
  method: 'GET',
  responseType: 'blob',
  params:data,
})

// 充电桩使用记录列表
export const getCharingUserList = data => DkAxios({
  url: `property/parking/chargingLogList/${data.number}`,
  method: 'GET',
  params:data,
})

// 充电桩列表
export const getCharingStationList = data => DkAxios({
  url: 'property/parking/chargingStationList',
  method: 'GET',
  params:data,
})

// 获取车位管理信息
export const getParkingInfo = data => DkAxios({
  url: 'property/parking/parkingManager',
  method: 'GET',
  params:data,
})

// 编辑车位管理信息
export const editParkingInfo = data => DkAxios({
  url: `property/parking/parkingManager?type=${data.type}&num=${data.num}`,
  method: 'PUT',
  data,
})

// 按出入口查询停车信息
export const getCarInOutInfo = data => DkAxios({
  url: 'property/parking/pay/GetCarInOutInfoByPlaceMap',
  method: 'GET',
  params:data,
})

// 企业月卡情况列表
export const getCompanyMonthCardList = data => DkAxios({
  url: 'property/parking/getCompanyMonthCardList',
  method: 'GET',
  params:data,
})

// 用户月卡情况列表
export const getUserMonthCardList = data => DkAxios({
  url: 'property/parking/getStaffMonthCardList',
  method: 'GET',
  params:data,
})


// 获取企业月统计数量
export const getCompanyCardMsg = data => DkAxios({
  url: 'property/parking/getCompanyMonthCardMsg',
  method: 'GET',
  params:data,
})

// 月卡使用记录
export const getMonthUserList = data => DkAxios({
  url: `property/parking/monthCardUseList/${data.staffUuid}`,
  method: 'GET',
  params:data,
})


// 充电桩使用记录列表导出
export const exportCharingLog = data => DkAxios({
  url: `property/parking/exportChargingLog/${data.number}`,
  method: 'GET',
  responseType: 'blob',
  params:data,
})

// 新增临时月卡
export const addTempMonthCard = data => DkAxios({
  url: 'property/parking/addTempMonthCard',
  method: 'POST',
  data,
})

// 按出入口查询停车信息导出
export const exportCarInOutInfo = data => DkAxios({
  url: 'property/parking/exportCarInOutInfo',
  method: 'GET',
  responseType: 'blob',
  params:data,
})

// 初始化新增月卡
export const initAddMonthCard = data => DkAxios({
  url: 'property/parking/initAddMonthCard',
  method: 'POST',
  data,
})

// 新增/编辑月卡组
export const addOrEditCardGroup = data => DkAxios({
  url: 'property/parking/monthAudit/addOrUpdateMonthCardGroup',
  method: 'POST',
  data,
})

// 新增/编辑月卡组企业
export const addOrEditCompanyGroup = data => DkAxios({
  url: 'property/parking/monthAudit/addOrUpdateMonthCardGroupCompany',
  method: 'POST',
  data,
})

// 获取月卡组审核企业列表
export const getAuditCompanyList = data => DkAxios({
  url: 'property/parking/monthAudit/company/department',
  method: 'GET',
  params: data,
})

// 获取月卡组审核企业列表下员工
export const getAuditCompanyStaffList = data => DkAxios({
  url: `property/parking/monthAudit/getAuditCompanyStaffList/${data.companyUuid}`,
  method: 'GET',
  params: data,
})

// 获取月卡组详情
export const getMonthCardGroupInfo = data => DkAxios({
  url: 'property/parking/monthAudit/getMonthCardGroupDetail',
  method: 'GET',
  params: data,
})

// 删除月卡组
export const delMonthCardGroup = data => DkAxios({
  url: `property/parking/monthAudit/monthCardGroup/${data.uuid}`,
  method: 'DELETE',
  data,
})

// 删除月卡组企业
export const delMonthCardGroupCompany = data => DkAxios({
  url: `property/parking/monthAudit/monthCardGroupCompany/${data.uuid}`,
  method: 'DELETE',
  data,
})

// 获取月卡组列表
export const getMonthCardGroupList = data => DkAxios({
  url: 'property/parking/monthAudit/monthCardGroupList',
  method: 'GET',
  params:data,
})

// 月卡组用户列表
export const getMonthCardGroupStaffList = data => DkAxios({
  url: 'property/parking/getMonthCardGroupStaffList',
  method: 'GET',
  params:data,
})

// 获卡月卡租审核企业列表
export const getAuditComanyByGroupList = data => DkAxios({
  url: `property/parking/monthAudit/getAuditCompanyByGroupList/${data.groupUuid}`,
  method: 'GET',
  params:data,
})

// 导出
export const exportUser  = data => DkAxios({
  url: 'property/parking/monthCardGroupStaffListExport',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 获取企业下的部门列表
export const getByCompanyGetDepart = data => DkAxios({
  url: `property/parking/monthAudit/company/department/all/${data.uuid}`,
  method: 'GET',
  params: data,
})

export const editAuditStaff = data => DkAxios({
  url:`staff/audit/staff?staffUuid=${data.staffUuid}`,
  method:'PUT',
  data,
})

export const getAuditStaff = data => DkAxios({
  url:'staff/audit/staff',
  method:'GET',
  params:data,
})

export const getMonthCardLog = data => DkAxios({
  url:'/property/parking/month-card/switch-records',
  method: 'GET',
  params: data,
})

export const exportMonthCardLog = data => DkAxios({
  url:'/property/parking/month-card/export/switch-records',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 将车牌设置为出场
export const setCarNo = data => DkAxios({
  url: `/property/parking/set/car?id=${data.id}`,
  method: 'PUT',
  data,
})

// 获取车位管理信息列表
export const getCarNoList = data => DkAxios({
  url: '/property/parking/infos',
  method: 'GET',
  params: data,
})

// 获取车位管理信息列表
export const exportCarNoList = data => DkAxios({
  url: '/property/parking/infos/export',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 获取充电授权列表
export const getCharingAuthList = data => DkAxios({
  url: '/property/parking/apply/charging',
  method: 'GET',
  params: data,
})

// 获取充电授权列表
export const handerCharingAuth = data => DkAxios({
  url: '/property/parking/apply/charging',
  method: 'PUT',
  data,
})

// 查询月卡与科拓信息不同的数据
export const getDiffMonthInfo = data => DkAxios({
  url: '/property/parking/diff/info',
  method: 'GET',
  params: data,
})

// 同步月卡与科拓信息不同的数据
export const handerDiffMonthInfo = data => DkAxios({
  url: '/property/parking/diff/info',
  method: 'PUT',
  data,
})

// 也查记录列表
export const inspectionRecords = params => DkAxios({
  url: '/property/inspection/records',
  method: 'GET',
  params,
})

// 导出夜查记录
export const inspectionRecordsExport = params => DkAxios({
  url: '/property/inspection/records/export',
  method: 'GET',
  responseType: 'blob',
  params,
})

// 异常记录列表
export const propertyInspectionSuspicious = params => DkAxios({
  url: '/property/inspection/suspicious',
  method: 'GET',
  params,
})

// 导出异常记录
export const propertyInspectionSuspiciousExport = params => DkAxios({
  url: '/property/inspection/suspicious/export',
  method: 'GET',
  responseType: 'blob',
  params,
})

// 异常详情
export const propertyInspectionSuspiciousDetail = id => DkAxios({
  url: `/property/inspection/suspicious/${id}`,
  method: 'GET',
})

// 移动月卡组
export const monthAuditMove = data => DkAxios({
  url: '/property/parking/monthAudit/move',
  method: 'PUT',
  data,
})

// 审核配置详情
export const parkingCardConfig = groupUuid => DkAxios({
  url: `/property/parking/card/config/${groupUuid}`,
  method: 'GET',
})

// 审核配置修改
export const parkingCardConfigEdit = data => DkAxios({
  url: '/property/parking/card/config',
  method: 'PUT',
  data,
})

// 免费车列表
export const especialCarList = params => DkAxios({
  url: '/property/parking/card/especial',
  method: 'GET',
  params,
})

// 免费车列表导出
export const especialCarListExport = params => DkAxios({
  url: '/property/parking/card/especial/export',
  method: 'GET',
  responseType: 'blob',
  params,
})

// 免费车删除
export const especialCarDelete = uuid => DkAxios({
  url: `/property/parking/card/especial/${uuid}`,
  method: 'DELETE',
})

// 免费车新增
export const especialCarAdd = data => DkAxios({
  url: '/property/parking/card/especial',
  method: 'POST',
  data,
})

// 免费车编辑
export const especialCarEdit = data => DkAxios({
  url: `/property/parking/card/especial/${data.uuid}`,
  method: 'PUT',
  data,
})

// 无牌车列表
export const noCard = params => DkAxios({
  url: '/property/parking/no-card',
  method: 'GET',
  params,
})

// 无牌车列表导出
export const noCardExport = params => DkAxios({
  url: '/property/parking/no-card/export',
  method: 'GET',
  responseType: 'blob',
  params,
})

// 设置禁用月卡车
export const parkingDisable = uuid => DkAxios({
  url: `/property/parking/disable/${uuid}`,
  method: 'PUT',
})

// 出场
export const parkingNoCard = uuid => DkAxios({
  url: `/property/parking/no-card/${uuid}`,
  method: 'PUT',
})
