import { UploadFile } from '../api/公共接口/公共资源接口';

export default {
    data() {
        return {
            hash: '',
        };
    },
    created() {},
    methods: {
        async onUploadImageMixins(file) {
            const formData = new FormData();

            formData.append('file', file);

            const { status, data } = await UploadFile(formData);

            if (status === 200 && data?.url) {
                this.hash = data.url;
            } else {
                this.$message.error('文件上传失败!');

                return;
            }
        },
    },
};
