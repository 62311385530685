<template>
  <div>
    <el-dialog
      title="报错提示"
      :visible.sync="dialogTableVisible">
      <div v-if="error">
        {{ error }}
      </div>
      <div
        slot="footer"
        class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogTableVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */


export default {
  data() {
    return {
      props: {
        error: {
          type: Object,
          default: () => {
          },
        },
        dialog: {
          type: Boolean,
          default: () => true,
        },
      },
    };
  },

  methods: {
  },
  watch: {
    dialog(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dialog = newVal
      }
    },
  },
};
</script>

<style scoped>
</style>
