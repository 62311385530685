<template>
  <el-container class="full-height bg-gray layout">
    <el-main class="page-content">
      <slot/>
    </el-main>
  </el-container>
</template>

<script>

import object from '../jslib/object'

export default {
  name: 'AppContent',
  watch: {
    $route(val) {
      if (val.name === 'info') {
        console.log(object.getWindowOffset())
      }
    },

  },
}
</script>

<style scoped>
.layout{
  overflow: auto;
  flex: 1;
}
.full-height {
  overflow-x: scroll;
}/*
::-webkit-scrollbar {
  display: none
} */
</style>
