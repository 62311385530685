/* eslint-disable consistent-return */
/**
 * 全局过滤器
 */

import PermissionMapper from '../configs/permission-mapper';

import CryptoJSAES from 'crypto-js/aes';
import CryptoJSEncUtf8 from 'crypto-js/enc-utf8';

export const pms2MenuList = permissionList => {
    const sideMemuList = [];

    console.log(permissionList);

    permissionList.forEach(value => {
        const menuItem = PermissionMapper.get(value.name);
        if (
            value.children &&
            Array.isArray(value.children) &&
            value.children.length
        ) {
            const { children } = value;
            menuItem.children = [];
            children.forEach(subValue => {
                const item = PermissionMapper.get(subValue.name);
                if (item) {
                    menuItem.children.push(item);
                }
            });
        }
        if (menuItem) {
            sideMemuList.push(menuItem);
        }
    });
    return sideMemuList;
};

export const encrypt = (data, secret = 'Dankal123') =>
    CryptoJSAES.encrypt(data, secret);

export const decrypt = (encryptedData, secret = 'Dankal123') => {
    const bytes = CryptoJSAES.decrypt(encryptedData.toString(), secret);
    const plaintext = bytes.toString(CryptoJSEncUtf8);
    return plaintext;
};

export const getOperationByName = (moduleName, operationList) => {
    // eslint-disable-next-line consistent-return
    operationList.forEach((value, index) => {
        console.log(index);
        if (value.name === moduleName) {
            return value.operations;
        }
    });
};

export const getRouterMetaByURL = url => {
    const permissionList = JSON.parse(
        decrypt(sessionStorage.getItem('permission'))
    );

    let meta = [];
    permissionList.forEach(value => {
        if (value.url === url) {
            meta = value;
            return;
        }
        if (value.children) {
            value.children.forEach(item => {
                if (item.url === url) {
                    meta.push(value);
                    meta.push(item);
                }
            });
        }
    });
    return meta;
};
