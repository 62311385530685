const AdminList = () => import('@building/vuepage/permission/admin-list.vue');
const GroupList = () => import('@building/vuepage/permission/group-list.vue');
const MenuList = () => import('@building/vuepage/permission/menu-list.vue');
const OperationLogs = () => import('@building/vuepage/permission/operation-logs.vue');
export default [
  {
    path: '/permission/admin-list',
    component: AdminList,
    meta: {
      tar: [
        {
          url: '',
          name: '权限管理',
        },
        {
          url: '/permission/admin-list',
          name: '管理员管理',
        },
      ],
    },
  },
  {
    path: '/permission/group-list',
    component: GroupList,
    meta: {
      tar: [
        {
          url: '',
          name: '权限管理',
        },
        {
          url: '/permission/group-list',
          name: '角色组管理',
        },
      ],
    },
  },
  {
    path: '/permission/menu-list',
    component: MenuList,
    meta: {
      tar: [
        {
          url: '',
          name: '权限管理',
        },
        {
          url: '/permission/menu-list',
          name: '菜单管理',
        },
      ],
    },
  },
  {
    path: '/permission/operation-logs',
    component: OperationLogs,
    meta: {
      tar: [
        {
          url: '',
          name: '权限管理',
        },
        {
          url: '/permission/operation-logs',
          name: '操作日志',
        },
      ],
    },
  },
]
