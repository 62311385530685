const CurrentList = () => import('@building/vuepage/visitor-manage/current-list.vue');
const VisitorReport = () => import('@building/vuepage/visitor-manage/visitor-report.vue');


export default [
  {
    path: '/visitor-manage/current-list',
    component: CurrentList,
    meta: {
      tar: [
        { name: '访客管理', url: '' },
        { name: '通行记录', url: '/visitor-manage/current-list' },
      ],
    },
  },
  {
    path: '/visitor-manage/visitor-report',
    component: VisitorReport,
    meta: {
      tar: [
        { name: '访客管理', url: '' },
        { name: '访客报表', url: '/visitor-manage/visitor-report' },
      ],
    },
  },
]
