const PropertyOrder = () => import('@building/vuepage/work-order-manage/property-order.vue');
const BuildOrder = () => import('@building/vuepage/work-order-manage/build-order.vue');
const PropertyInfo = () => import('@building/vuepage/work-order-manage/property-info.vue');
const BuildInfo = () => import('@building/vuepage/work-order-manage/build-info.vue');

export default [
  {
    path: '/work-order-manage/property-order',
    component: PropertyOrder,
    meta: {
      tar: [
        { name: '工单管理', url: '' },
        { name: '物业工单', url: '/work-order-manage/property-order' },
      ],
    },
  },
  {
    path: '/work-order-manage/property-order/property-info',
    component: PropertyInfo,
    meta: {
      tar: [
        { name: '工单管理', url: '' },
        { name: '物业工单', url: '/work-order-manage/property-order' },
        { name: '详情', url: '' },
      ],
    },
  },
  {
    path: '/work-order-manage/build-order',
    component: BuildOrder,
    meta: {
      tar: [
        { name: '工单管理', url: '' },
        { name: '楼层工单', url: '/work-order-manage/build-order' },
      ],
    },
  },
  {
    path: '/work-order-manage/build-order/build-info',
    component: BuildInfo,
    meta: {
      tar: [
        { name: '工单管理', url: '' },
        { name: '楼层工单', url: '/work-order-manage/build-order' },
        { name: '详情', url: '' },
      ],
    },
  },
]
