/* eslint-disable import/prefer-default-export */
import DkAxios from '@building/jslib/dk-axios'


// 新增/编辑楼层空间
export const addOrEditBuildPlace = data => DkAxios({
  url: 'property/company/addOrUpdateBuilding',
  method: 'POST',
  data,
})

// 新增/编辑入驻企业
export const addOrEditCompany = data => DkAxios({
  url: 'property/company/addOrUpdateCompany',
  method: 'POST',
  data,
})

// 新增入驻企业
export const addCompany = data => DkAxios({
  url: 'property/company/company/addCompanyInfos',
  method: 'POST',
  data,
})

// 编辑入驻企业
export const editCompany = data => DkAxios({
  url: 'property/company/company/editCompanyInfos',
  method: 'PUT',
  data,
})

// 获取楼宇数据字典
export const getDataMsg = data => DkAxios({
  url: 'property/company/getBuildingDataMsg',
  method: 'GET',
  params: data,
})

// 删除租户
export const delCompany = data => DkAxios({
  url: `property/company/company/${data.uuid}`,
  method: 'DELETE',
  data,
})

// 根据楼层获取对应房间号
export const getRoom = data => DkAxios({
  url: 'property/company/getBuildingRoomsByFloor',
  method: 'GET',
  params: data,
})

// 删除楼宇
export const delBuild = data => DkAxios({
  url: `property/company/buildingRoom/${data.uuid}`,
  method: 'DELETE',
  data,
})


// 楼宇信息批量导出
export const exportBuildList = data => DkAxios({
  url: 'property/company/exportBuildingList',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 企业信息批量导出
export const exportCompanyList = data => DkAxios({
  url: 'property/company/exportCompanyList',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 员工信息批量导出
export const exportstaffList = data => DkAxios({
  url: 'property/company/exportStaffList',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 楼宇列表
export const getBuildList = data => DkAxios({
  url: 'property/company/getBuildingList',
  method: 'GET',
  params: data,
})

// 楼宇视图列表
export const getBuildRoomList = data => DkAxios({
  url: 'property/company/getBuildingRoomCard',
  method: 'GET',
  params: data,
})

// 企业管理 企业详情查询
export const getCompanyInfo = data => DkAxios({
  url: 'property/company/getCompanyDetail',
  method: 'GET',
  params: data,
})

// 企业管理 企业列表查询
export const getCompanyList = data => DkAxios({
  url: 'property/company/getCompanyList',
  method: 'GET',
  params: data,
})

// 楼宇信息批量导入
export const importBuild = data => DkAxios({
  url: 'property/company/import/multipartAddBuilding',
  method: 'POST',
  data,
})

// 企业信息批量导入
export const importCompany = data => DkAxios({
  url: 'property/company/import/multipartAddCompany',
  method: 'POST',
  data,
})

// 员工信息批量导入
export const importStaff = data => DkAxios({
  url: 'staff/import/multipart_add_staff',
  method: 'POST',
  data,
})

// 楼宇批量导入模板下载
export const downloadBuild = data => DkAxios({
  url: 'property/company/mould/buildimg',
  method: 'GET',
  params: data,
})

// 企业批量导入模板下载
export const downloadCompany = data => DkAxios({
  url: 'property/company/mould/company',
  method: 'GET',
  params: data,
})

// 员工批量导入模板下载
export const downloadStaff = data => DkAxios({
  url: 'property/company/mould/staff',
  method: 'GET',
  params: data,
})

// 设为楼管
export const saveBuildManager = data => DkAxios({
  url: 'property/company/buildingManager/saveBuildingManager',
  method: 'POST',
  data,
})

// 取消楼管
export const delBuildManager = data => DkAxios({
  url: `property/company/buildingManager/deleteBuildingManager/${data.companyUuid}`,
  method: 'DELETE',
  data,
})

// 获取楼层数据
export const getCompanyBuildList = data => DkAxios({
  url: 'property/company/buildingManager/getBuildingManagerFloorList',
  method: 'GET',
  params: data,
})

// 获取用户列表
export const getUserList = data => DkAxios({
  url: 'property/user/users',
  method: 'GET',
  params: data,
})

// 获取用户详情
export const getUserInfo = data => DkAxios({
  url: `property/user/detail/${data.uuid}`,
  method: 'GET',
  params: data,
})

// 编辑公司楼宇
export const editCompanyBuild = data => DkAxios({
  url: 'property/company/company/floor',
  method: 'PUT',
  data,
})

// 获取公司楼宇信息
export const getCompanyBuildInfo = data => DkAxios({
  url: `/property/company/company/floor/${data.uuid}`,
  method: 'GET',
  params: data,
})

// 关闭公司
export const closeCompany = data => DkAxios({
  url: `/property/company/retreat/company/${data.uuid}`,
  method: 'DELETE',
  data,
})

// 关闭公司详情
export const getCloseCompanyInfo = data => DkAxios({
  url: `/property/company/retreat/company/${data.uuid}`,
  method: 'GET',
  params: data,
})

// 获取关闭公司列表
export const getCloseCompany = data => DkAxios({
  url: '/property/company/retreat/companies',
  method: 'GET',
  params: data,
})

// 导出关闭公司列表
export const exportCloseCompany = data => DkAxios({
  url: '/property/company/export/retreat/companies',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 获取关闭公司员工
export const getCloseCompanyStaff = data => DkAxios({
  url: '/property/staff/retreat/staff',
  method: 'GET',
  params: data,
})

// 导出关闭公司员工
export const exportCloseCompanyStaff = data => DkAxios({
  url: '/property/company/export/retreat/staff',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 获取关闭公司员工详情
export const getCloseCompanyStaffInfo = data => DkAxios({
  url: `/property/staff/retreat/staff/${data.uuid}`,
  method: 'GET',
  params: data,
})

// 获取供应商列表
export const getSuppllierList = data => DkAxios({
  url: '/property/company/getProviderList',
  method: 'GET',
  params: data,
})

// 获取供应商详情
export const getSuppllierInfo = data => DkAxios({
  url: `/property/company/get/${data.uuid}`,
  method: 'GET',
  params: data,
})

// 导入供应商
export const exportSupplier = data => DkAxios({
  url: '/property/company/import/multipart_add_provide',
  method: 'POST',
  data,
})

// 供应商 模板下载
export const uploadSupplier = data => DkAxios({
  url: 'property/company/mould/provide',
  method: 'GET',
  responseType: 'blob',
  params: data,
})

// 编辑供应商
export const editSupplier = data => DkAxios({
  url: '/property/company/provide_info',
  method: 'PUT',
  data,
})

// 删除供应商
export const delSupplier = data => DkAxios({
  url: `/property/company/provide/${data.uuid}`,
  method: 'DELETE',
  data,
})

// 新增供应商
export const addSupplier = data => DkAxios({
  url: '/property/company/save/provide_info',
  method: 'POST',
  data,
})

// 获取审核企业列表
export const getApplyCompanyList = data => DkAxios({
  url: '/property/company/company/settleIn',
  method: 'GET',
  params: data,
})

// 拒绝审核企业
export const rejectCompany = data => DkAxios({
  url: '/property/company/company/refuse_voucher',
  method: 'POST',
  data,
})

// 同意审核企业
export const passCompany = data => DkAxios({
  url: `/property/company/company/adopt_voucher/${data.companyUuid}`,
  method: 'PUT',
  data,
})

// 获取审核企业详情
export const getApplyCompanyInfo = data => DkAxios({
  url: '/company/query/details',
  method: 'GET',
  params: data,
})
