<template>
  <div>
    <el-button
      @click="openUpload"
      type="primary"
      size="small">上传文件</el-button>
    <input
      @change="onFileSelect"
      ref="file"
      type="file"
      style="display: none;"
      multiple>
  </div>
</template>

<script>
import BulkUpload from '@building/mixins/bulk-upload'

export default {
  name: 'BulkUpload',
  mixins: [BulkUpload],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openUpload() {
      this.$refs.file.click()
    },
    onFileSelect() {
      const files = [...this.$refs.file.files]
      for (let i = 0; i < files.length; i += 1) {
        if (files[i].name.indexOf('.xls') === -1 && files[i].name.indexOf('.pdf') === -1 && files[i].name.indexOf('doc') === -1
        ) {
          this.$message({
            message: '只能上传word/excel/pdf格式的文件',
            type: 'info',
          })
          return
        }
      }
      for (let i = 0; i < files.length; i += 1) {
        if (files[i].name.includes('.pdf')) {
          console.log('---------------')
          files[i].typeUrl = 'pdf'
        } else if (files[i].name.includes('.doc')) {
          files[i].typeUrl = 'doc'
        } else {
          files[i].typeUrl = 'xls'
        }
      }
      console.log(files)
      let fileList = []
      files.forEach((i) => {
        fileList.push({
          name: i.name,
          file: i,
          percent: 0,
          key: '',
          size: i.size,
        })
      })
      fileList = [...this.value, ...fileList]
      const newList = []
      const obj = {}
      fileList.forEach((i) => {
        if (!obj[i.name]) {
          obj[i.name] = i.name
          newList.push(i)
        }
      })
      this.$emit('input', newList)
      console.log(newList)
      this.$refs.file.value = ''
      this.initFileList(newList)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
