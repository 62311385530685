import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  userInfo: {},
  name: 'isv',
  bucket_domain: '',
  bucket_domain_token: '',
  regionData: {},
  regionCity: {},
  isKeepAlive: true,
}
export default {
  actions,
  getters,
  state,
  mutations,
}
