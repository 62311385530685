import Vue from 'vue';
import env from '@building/configs/environment';

Vue.filter('imgMapper', value => env.imgDomain + value);

Vue.filter('imgFilter', value => {
    if (value === null || value === '') {
        return value;
    }
    if (value.indexOf('https') !== -1 || value.indexOf('http') !== -1) {
        return value;
    }
    return env.imgDomain + value;
});
