const InvoiceList = () => import('@building/vuepage/invoice-manage/invoice-list.vue');


export default [
  {
    path: '/invoice-manage/invoice-list',
    component: InvoiceList,
    meta: {
      tar: [
        { name: '发票管理', url: '' },
        { name: '发票列表', url: '/invoice-manage/invoice-list' },
      ],
    },
  },
]
