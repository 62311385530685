/**
 * Created by IvanCai on 2017/4/19.
 * Modified by Zhi on 2018/7/8.
 */
import axios from 'axios';
import env from '@building/configs/environment';

const dkAxios = axios.create({
    baseURL: env.devApiDomain,
    timeout: 60000, // 设置超时时间
});

let loading;
dkAxios.interceptors.request.use(
    config => {
        // 访问网络时加载loading,防止用户多次操作
        const token = sessionStorage.getItem('X-Access-Token');
        config.headers = { 'X-Access-Token': token };

        loading = vm.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
        });

        return config;
    },
    error => Promise.reject(error)
);

dkAxios.interceptors.response.use(
    response => {
        loading.close();
        return response;
    },
    error => {
        loading.close();
        /* eslint-disable */
    const { status, data } = error.response;
    switch (status) {
      case 401:
      case 412:
        vm.$router.replace('/login/log');
        break;
      default:
        vm.$message.error({ message: data.error.message, duration: 2000 }); 
        return Promise.reject(error);
    }
  },
)


export default dkAxios;
