const WaterTable = () => import('@building/vuepage/finance/water-table.vue');

export default [
  {
    path: '/finance/water-table',
    component: WaterTable,
    meta: {
      tar: [
        { name: '财务管理', url: '' },
        { name: '数据图表', url: '/pro-examine-manage/card-examine' },
      ],
    },
  },
]
