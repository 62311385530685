import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import Example from '@building/router/example';
import Commodity from '@building/router/commodity';
import Permission from '@building/router/permission';
import ComExamineManage from '@building/router/com-examine-manage';
import TenantManage from '@building/router/tenant-manage';
import PropertyManage from '@building/router/property-manage';
import ParkManage from '@building/router/park-manage';
import ProExamineManage from '@building/router/pro-examine-manage';
import OrderManage from '@building/router/order-manage';
import WorkOrderManage from '@building/router/work-order-manage';
import ContentManage from '@building/router/content-manage';
import Release from '@building/router/release';
import Finance from '@building/router/finance';
import Invoice from '@building/router/invoice';
import Visitor from '@building/router/visitor-manage';
import HistoryTenant from '@building/router/history-tenant';
import AttendanceManage from '@building/router/attendance-manage';
// progress bar style
NProgress.configure({ showSpinner: false });

const Login = () => import('@building/vuepage/index/login.vue');
const Index = () => import('@building/vuepage/Index.vue');
const children = [
    {
        path: '',
        redirect: '/example-management/multiple-download',
    },
].concat(
    ...Example,
    ...Commodity,
    ...Permission,
    ...ComExamineManage,
    ...TenantManage,
    ...PropertyManage,
    ...ParkManage,
    ...Release,
    ...ProExamineManage,
    ...OrderManage,
    ...WorkOrderManage,
    ...ContentManage,
    ...Finance,
    ...Invoice,
    ...Visitor,
    ...HistoryTenant,
    ...AttendanceManage
);

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '',
            redirect: '/login/log',
        },
        {
            path: '/login/:type',
            component: Login,
        },
        {
            path: '/index',
            component: Index,
            children,
        },
    ],
});

router.beforeEach((to, from, next) => {
  /* eslint-disable */
  if (to.name === 'info.vue') {
    from.meta.keepAlive = true;
  } else {
    from.meta.keepAlive = false;
  }
  
  NProgress.start()
  next();
});


// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  NProgress.done()
})

export default router;
