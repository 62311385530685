// import * as qiniu from 'qiniu-js';

// const uuid = require('uuid')
// import { v4 as uuidv4 } from 'uuid';

import { UploadFile } from '@enterpaise/api/公共接口/公共资源接口';

export default {
    data() {
        return {
            subscription: '',
        };
    },
    methods: {
        initFileList(data) {
            if (data.length) {
                this.createTxUpload(data, 0);
            }
        },
        
        // 腾讯的上传
        async createTxUpload(files) {
            const errMsg = [];

            for (let index = 0; index < files.length; index++) {
                const tmpFile = files[index];

                if (tmpFile.url) {
                    // 文件信息包含url，则表示该文件已上传，不重复上传
                    continue;
                }

                const formData = new FormData();
                formData.append('file', tmpFile.file);

                await UploadFile(formData)
                    .then(re => {
                        const tmpData = re.data || {};

                        if (tmpData.url) {
                            tmpFile.url = tmpData.url;
                        } else {
                            errMsg.push(tmpFile.name);
                        }
                    })
                    .catch(() => {
                        errMsg.push(tmpFile.name);
                    });
            }

            if (errMsg.length) {
                this.$notify({
                    title: '失败',
                    message: '文件【' + errMsg.join('、') + '】上传失败',
                    type: 'error',
                });
            }

            const tmpNewFiles = files.filter(item => !!item.url);

            this.$emit('input', tmpNewFiles);
        },
        uploadPause() {
            if (this.subscription) {
                this.subscription.unsubscribe(); // 上传取消
            }
        },
    },
};
