const ParkingLot = () => import('@building/vuepage/park-manage/parking-lot.vue');
const MonthCard = () => import('@building/vuepage/park-manage/month-card.vue');
const AddMonthCard = () => import('@building/vuepage/park-manage/add-month-card.vue');
const MonthInfo = () => import('@building/vuepage/park-manage/month-info.vue');
const ParkingManage = () => import('@building/vuepage/park-manage/parking-manage.vue');
const CharingManage = () => import('@building/vuepage/park-manage/charging-manage.vue');
const CharingRepair = () => import('@building/vuepage/park-manage/charing-repair.vue');
const CharingRepairInfo = () => import('@building/vuepage/park-manage/charing-repair-info.vue');
const CharingManageInfo = () => import('@building/vuepage/park-manage/charing-manage-info.vue');
const WatchUser = () => import('@building/vuepage/park-manage/watch-user.vue');
const MonthCardGroup = () => import('@building/vuepage/park-manage/month-card-group.vue');
const MonthCardLog = () => import('@building/vuepage/park-manage/month-card-log.vue');
const CarList = () => import('@building/vuepage/park-manage/car-list.vue');
const CharingAuth = () => import('@building/vuepage/park-manage/charing-auth.vue');

// 夜查记录
const NightRecord = () => import('@building/vuepage/park-manage/night-record/index.vue');
const AbnormalRecord = () => import('@building/vuepage/park-manage/night-record/abnormal-record.vue');
const AbnormalDetail = () => import('@building/vuepage/park-manage/night-record/abnormal-detail.vue');

// 免费车管理
const CarEspecial = () => import('@building/vuepage/park-manage/car-especial/index.vue');

// 无牌车列表
const NoCar = () => import('@building/vuepage/park-manage/no-card.vue');

export default [
  {
    path: '/park-manage/parking-lot',
    component: ParkingLot,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '车位管理', url: '/park-manage/parking-lot' },
      ],
    },
  },
  {
    path: '/park-manage/car-list',
    component: CarList,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '场内车辆信息', url: '/park-manage/car-list' },
      ],
    },
  },
  {
    path: '/pack-manage/month-card-log',
    component: MonthCardLog,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '月卡切换日志', url: '/park-manage/month-card-log' },
      ],
    },
  },
  {
    path: '/park-manage/month-card',
    component: MonthCard,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '月卡管理', url: '/park-manage/month-card' },
      ],
    },
  },
  {
    path: '/park-manage/month-card/month-info',
    component: MonthInfo,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '月卡管理', url: '/park-manage/month-card' },
        { name: '查看用户记录', url: '/park-manage/month-card/month-info' },
      ],
    },
  },
  {
    path: '/park-manage/month-card/watch-user',
    component: WatchUser,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '月卡管理', url: '/park-manage/month-card' },
        { name: '查看用户', url: '/park-manage/month-card/watch-user' },
      ],
    },
  },
  {
    path: '/park-manage/month-card/audit-group',
    component: MonthCardGroup,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '月卡管理', url: '/park-manage/month-card' },
        { name: '月卡组审批', url: '/park-manage/month-card/audit-group' },
      ],
    },
  },
  {
    path: '/park-manage/month-card/add',
    component: AddMonthCard,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '月卡管理', url: '/park-manage/month-card' },
        { name: '新增月卡', url: '/park-manage/month-card/add' },
      ],
    },
  },
  {
    path: '/park-manage/charing-manage',
    component: CharingManage,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '充电桩管理', url: '/park-manage/charing-manage' },
      ],
    },
  },
  {
    path: '/park-manage/charing-manage/info',
    component: CharingManageInfo,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '充电桩管理', url: '/park-manage/charing-manage' },
        { name: '查看详情', url: '/park-manage/charing-manage/info' },
      ],
    },
  },
  {
    path: '/park-manage/charing-repair',
    component: CharingRepair,
    meta: {
      keepAlive: true,
      tar: [
        { name: '停车场管理', url: '' },
        { name: '充电桩报修', url: '/park-manage/charing-repair' },
      ],
    },
  },
  {
    path: '/park-manage/charing-repair/info',
    component: CharingRepairInfo,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '充电桩报修', url: '/park-manage/charing-repair' },
        { name: '查看详情', url: '/park-manage/charing-repair/info' },
      ],
    },
    name: 'info',
  },
  {
    path: '/park-manage/parking-manage',
    component: ParkingManage,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '停车管理', url: '/park-manage/parking-manage' },
      ],
    },
  },
  {
    path: '/park-manage/charing-auth',
    component: CharingAuth,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '充电权限申请', url: '/park-manage/charing-auth' },
      ],
    },
  },
  {
    path: '/park-manage/night-record',
    component: NightRecord,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '夜查记录', url: '/park-manage/night-record' },
      ],
    },
  },
  {
    path: '/park-manage/abnormal-record',
    component: AbnormalRecord,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '夜查记录', url: '/park-manage/night-record' },
        { name: '异常记录', url: '/park-manage/abnormal-record' },
      ],
    },
  },
  {
    path: '/park-manage/abnormal-detail/:id',
    component: AbnormalDetail,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '夜查记录', url: '/park-manage/night-record' },
        { name: '异常记录', url: '' },
        { name: '详情', url: '' },
      ],
    },
  },
  {
    path: '/park-manage/car-especial',
    component: CarEspecial,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '免费车管理', url: '/park-manage/car-especial' },
      ],
    },
  },
  {
    path: '/park-manage/no-card',
    component: NoCar,
    meta: {
      tar: [
        { name: '停车场管理', url: '' },
        { name: '无牌车列表', url: '/park-manage/no-card' },
      ],
    },
  },
]
