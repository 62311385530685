const AttendanceArea = () => import('@building/vuepage/attendance-manage/attendance-area/index.vue');
const ShiftSetting = () => import('@building/vuepage/attendance-manage/shift-setting/index.vue');
const ScheduleManage = () => import('@building/vuepage/attendance-manage/schedule-manage/index.vue');
const HolidaySetting = () => import('@building/vuepage/attendance-manage/schedule-manage/holiday-setting.vue');
const HourStatistics = () => import('@building/vuepage/attendance-manage/schedule-manage/hour-statistics.vue');
const StatisticsDetail = () => import('@building/vuepage/attendance-manage/schedule-manage/statistics-detail.vue');
const ScheduleTable = () => import('@building/vuepage/attendance-manage/schedule-manage/schedule-table.vue');

export default [
  {
    path: '/attendance-manage/attendance-area',
    component: AttendanceArea,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '考勤地点编辑', url: '/attendance-manage/attendance-area' },
      ],
    },
  },
  {
    path: '/attendance-manage/shift-setting',
    component: ShiftSetting,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '班次设置', url: '/attendance-manage/shift-setting' },
      ],
    },
  },
  {
    path: '/attendance-manage/schedule-manage',
    component: ScheduleManage,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '排班管理', url: '/attendance-manage/schedule-manage' },
      ],
    },
  },
  {
    path: '/attendance-manage/holiday-setting',
    component: HolidaySetting,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '排班管理', url: '/attendance-manage/schedule-manage' },
        { name: '节假日设置', url: '/attendance-manage/holiday-setting' },
      ],
    },
  },
  {
    path: '/attendance-manage/hour-statistics/:month/:id',
    component: HourStatistics,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '排班管理', url: '/attendance-manage/schedule-manage' },
        { name: '工时统计', url: '' },
      ],
    },
  },
  {
    path: '/attendance-manage/statistics-detail/:id/:staffUuid/:staffName',
    component: StatisticsDetail,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '排班管理', url: '/attendance-manage/schedule-manage' },
        { name: '工时统计', url: '' },
        { name: '详情', url: '' },
      ],
    },
  },
  {
    path: '/attendance-manage/schedule-table/:id',
    component: ScheduleTable,
    meta: {
      tar: [
        { name: '考勤管理', url: '' },
        { name: '排班管理', url: '/attendance-manage/schedule-manage' },
        { name: '排班表管理', url: '' },
      ],
    },
  },
];
