const CardExamine = () =>
    import('@building/vuepage/pro-examine-manage/card-examine.vue');
const ReleaseManage = () =>
    import('@building/vuepage/pro-examine-manage/release-manage.vue');
const InvitationManage = () =>
    import('@building/vuepage/pro-examine-manage/invitation-manage.vue');
const CardInfo = () =>
    import('@building/vuepage/pro-examine-manage/card-info.vue');
const ReleaseInfo = () =>
    import('@building/vuepage/pro-examine-manage/release-info.vue');
const InvitationInfo = () =>
    import('@building/vuepage/pro-examine-manage/invitation-info.vue');
const WhiteInfo = () =>
    import('@building/vuepage/pro-examine-manage/white-visitor-info.vue');
const InvitationHistory = () =>
    import('@building/vuepage/pro-examine-manage/invitation-history.vue');
const SubCardExamine = () =>
    import('@building/vuepage/pro-examine-manage/sub-card-examine.vue');
const SubCardExamineDetail = () =>
    import('@building/vuepage/pro-examine-manage/sub-card-examine-detail.vue');

export default [
    {
        path: '/pro-examine-manage/card-examine',
        component: CardExamine,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                { name: '月卡审核', url: '/pro-examine-manage/card-examine' },
            ],
        },
    },
    {
        path: '/pro-examine-manage/card-examine/card-info',
        component: CardInfo,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                { name: '月卡审核', url: '/pro-examine-manage/card-examine' },
                {
                    name: '查看详情',
                    url: '/pro-examine-manage/card-examine/card-info',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/release-manage',
        component: ReleaseManage,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                { name: '物品放行', url: '/pro-examine-manage/release-manage' },
            ],
        },
    },
    {
        path: '/pro-examine-manage/release-manage/replease-info',
        component: ReleaseInfo,
        meta: {
            keepAlive: true,
            tar: [
                { name: '审核管理', url: '' },
                { name: '物品放行', url: '/pro-examine-manage/release-manage' },
                {
                    name: '查看详情',
                    url: '/pro-examine-manage/release-manage/replease-info',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/invitation-manage',
        component: InvitationManage,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '访客邀请',
                    url: '/pro-examine-manage/invitation-manage',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/invitation-manage/invitation-history',
        component: InvitationHistory,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '访客邀请',
                    url: '/pro-examine-manage/invitation-manage',
                },
                {
                    name: '来访记录',
                    url: '/pro-examine-manage/invitation-manage/invitation-history',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/invitation-manage/invitation-history/info',
        component: WhiteInfo,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '访客邀请',
                    url: '/pro-examine-manage/invitation-manage',
                },
                {
                    name: '来访记录',
                    url: '/pro-examine-manage/invitation-manage/invitation-history',
                },
                { name: '详情' },
            ],
        },
    },
    {
        path: '/pro-examine-manage/invitation-manage/invitation-info',
        component: InvitationInfo,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '访客邀请',
                    url: '/pro-examine-manage/invitation-manage',
                },
                {
                    name: '查看详情',
                    url: '/pro-examine-manage/card-examine/invitation-info',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/invitation-manage/white-info',
        component: WhiteInfo,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '访客邀请',
                    url: '/pro-examine-manage/invitation-manage',
                },
                {
                    name: '查看详情',
                    url: '/pro-examine-manage/card-examine/invitation-info',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/sub-card-examine',
        component: SubCardExamine,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '副卡审核',
                    url: '/pro-examine-manage/sub-card-examine',
                },
            ],
        },
    },
    {
        path: '/pro-examine-manage/sub-card-examine-detail/:uuid',
        component: SubCardExamineDetail,
        meta: {
            tar: [
                { name: '审核管理', url: '' },
                {
                    name: '副卡审核',
                    url: '/pro-examine-manage/sub-card-examine',
                },
                { name: '详情', url: '' },
            ],
        },
    },
];
