<template>
  <el-autocomplete
    popper-class="my-autocomplete"
    v-model="homeName"
    placeholder="请选择企业"
    :fetch-suggestions="querySearch"
    @select="handleSelect">
    <i
      class="el-icon-edit el-input__icon"
      slot="suffix"/>
    <template slot-scope="{ item }">
      <div class="name">{{ item.name }}</div>
    </template>
  </el-autocomplete>
</template>

<script>


import { getAuditCompanyList, getAuditComanyByGroupList } from '../api/park-manage'

export default {
  props: {
    value: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    groupid: {
      type: String,
      default: () => '',
    },
    // eslint-disable-next-line
    all: {
      type: Number,
    },
  },
  data() {
    return {
      homeName: '',
      homeList: [], // 数据
      id: '',
      status: '',
      queryData: {
        pageIndex: 1,
        pageSize: 300,
        keyword: '',
      },
      groupUuid: '',
    }
  },
  created() {
  },
  methods: {
    handleSelect(item) {
      this.homeName = item.name
      this.id = item.uuid
      this.$emit('select', this.id, this.homeName)
    },
    /* eslint-disable */ 
    querySearch(queryString, cb) {
        var restaurants = this.homeList;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
    createFilter(queryString) {
      return logisticsCompany => (logisticsCompany.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    },
    network() {
      return {
        getAuditCompanyList: async () => {
          const { data, status } = await getAuditCompanyList()
          if (status !== 200) return
          this.homeList = data.data
          console.log(1)
        },
        getAuditComanyByGroupList: async () => {
          const { status, data }  =await getAuditComanyByGroupList({
            groupUuid: this.groupid
          })
          if(status !== 200) return
          this.homeList = data
           console.log(2)
        },
      }
    },
  },
  watch:{                        
    //  监控 homeName 如果为空   就将商品的option清空
    homeName(val){
      if(val===''){
        this.$emit('change',1)
      }
    },
    groupid: {
      handler(val) {
        if (val) {
          this.network().getAuditComanyByGroupList()
        } 
      },
      immediate: true,
    },
    all: {
      handler(val) {
        if (val) {
          this.network().getAuditCompanyList()
        }
      },
      immediate: true,
    },
    value:{
      handler(val){
        if(!val){
          this.homeName = ''
      }
    },
     immediate:true
    },
    name:{
      handler(val){
        if(val){
          this.homeName = val
          return
        }
        this.homeName = ''
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
</style>
