/* eslint-disable vue/component-definition-name-casing */
import Vue from 'vue';
import ElementUI from 'element-ui';
import App from '@building/App.vue';
import router from '@building/router/index';
import 'element-ui/lib/theme-chalk/index.css';

import TopBar from '@building/components/top-bar.vue';
import AppContent from '@building/components/app-content.vue';
import DkTable from '@building/components/table-pagination.vue';
import Toolbar from '@building/components/toolbar.vue';
import UploadImage from '@building/components/upload-image.vue';
import Quill from '@building/components/quill-editor.vue';
import MessageDialog from '@building/components/message-dialog.vue';
import UploadExcel from '@building/components/bulk-upload.vue';
import CompanyList from '@building/components/company-list.vue';
import HomeList from '@building/components/home-list.vue';

import preview from 'vue-photo-preview';
import VCharts from 'v-charts';
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/index.css';

// vuex 配置
import store from '@building/vuex/store';
// quill 富文本编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'swiper/css';
import 'vue-photo-preview/dist/skin.css';

import '@building/filters/vue-filiter';
import '@building/jslib/verify-code.min';

// require('big-integer');
// require('js-file-download');

// 阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false;

// Vue Devtools 是否启用
Vue.config.devtools = true;

Vue.use(ElementUI);
Vue.use(preview);
Vue.use(VCharts);
Vue.use(VXETable);

// 每个页面都需要用的组件可以注册成全局组件，方便每个页面使用
Vue.component('top-bar', TopBar);
Vue.component('app-content', AppContent);
Vue.component('dk-table', DkTable);
Vue.component('toolbar', Toolbar);
Vue.component('upload-image', UploadImage);
Vue.component('quill', Quill);
Vue.component('message-dialog', MessageDialog);
Vue.component('home-list', HomeList);
Vue.component('company-list', CompanyList);
Vue.component('upload-excel', UploadExcel);

Vue.prototype.$confirmDialog = val =>
    Vue.prototype.$confirm(val, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    });

// 打开新页面处理
window.pageOpen = function (path) {
    if (import.meta.env.VITE_NODE_ENV === 'development') {
        window.open('/pages/building' + path);
    } else {
        window.open(path);
    }
};

/* eslint-disable no-new */
window.vm = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});
/* eslint-disable no-new */
