const TenantList = () => import('@building/vuepage/tenant-manage/tenant-list.vue');
const TenantAdd = () => import('@building/vuepage/tenant-manage/tenant-add.vue');
const StaffManage = () => import('@building/vuepage/tenant-manage/staff-manage.vue');
const StaffAdd = () => import('@building/vuepage/tenant-manage/staff-add.vue');
const StaffInfo = () => import('@building/vuepage/tenant-manage/staff-info.vue');
const SectionList = () => import('@building/vuepage/tenant-manage/section-list.vue');
const TenantDetail = () => import('@building/vuepage/tenant-manage/tenant-detail.vue');
const ListView = () => import('@building/vuepage/tenant-manage/list-view.vue');
const BuildingManage = () => import('@building/vuepage/tenant-manage/building-manage.vue');
const DepartList = () => import('@building/vuepage/tenant-manage/depart-list.vue');
const UserList = () => import('@building/vuepage/tenant-manage/user-list.vue');
const UserInfo = () => import('@building/vuepage/tenant-manage/user-info.vue');
const SupplierList = () => import('@building/vuepage/tenant-manage/supplier-list.vue');
const AddSupplier = () => import('@building/vuepage/tenant-manage/add-supplier.vue');
const TenantStaff = () => import('@building/vuepage/tenant-manage/tenant-staff.vue');
const TenantApply = () => import('@building/vuepage/tenant-manage/apply-tenant.vue');
const TenantApplyInfo = () => import('@building/vuepage/tenant-manage/apply-info.vue');

export default [
  {
    path: '/tenant-manage/apply-tenant',
    component: TenantApply,
    meta:
    {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业入驻审核', url: '/tenant-manage/apply-tenant' },
      ],
    },
  },
  {
    path: '/tenant-manage/apply-tenant/info',
    component: TenantApplyInfo,
    meta:
    {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业入驻审核', url: '/tenant-manage/apply-tenant' },
        { name: '详情', url: '' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list',
    component: TenantList,
    meta:
    {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-staff',
    component: TenantStaff,
    meta:
    {
      tar: [
        { name: '企业管理', url: '' },
        { name: '员工列表', url: '/tenant-manage/tenant-staff' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/tenant-info',
    component: TenantDetail,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '查看详情', url: '' }],
    },
    name: 'info',
  },
  {
    path: '/tenant-manage/tenant-list/tenant-add',
    component: TenantAdd,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '新增企业', url: '' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/supplier-list',
    component: SupplierList,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '供应商列表', url: '' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/supplier-list/add',
    component: AddSupplier,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '供应商列表', url: '/tenant-manage/tenant-list/supplier-list' },
        { name: '新增/编辑', url: '' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/tenant-edit',
    component: TenantAdd,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '编辑企业', url: '' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/staff-list',
    component: StaffManage,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '员工管理', url: '/tenant-manage/tenant-list/staff-list' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/staff-list/info',
    component: StaffInfo,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '员工管理', url: '/tenant-manage/tenant-list/staff-list' },
        { name: '查看详情', url: '/tenant-manage/tenant-list/staff-list/info' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/staff-list/add',
    component: StaffAdd,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '员工管理', url: '/tenant-manage/tenant-list/staff-list' },
        { name: '新增员工', url: '/tenant-manage/tenant-list/staff-list/add' }],
    },
  },
  {
    path: '/tenant-manage/tenant-list/staff-list/edit',
    component: StaffAdd,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '员工管理', url: '/tenant-manage/tenant-list/staff-list' },
        { name: '编辑员工', url: '/tenant-manage/tenant-list/staff-list/edit' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/staff-list/depart',
    component: DepartList,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '员工管理', url: '/tenant-manage/tenant-list/staff-list' },
        { name: '部门管理', url: '/tenant-manage/tenant-list/staff-list/depart' },
      ],
    },
  },
  {
    path: '/tenant-manage/tenant-list/staff-list/section-list',
    component: SectionList,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '企业列表', url: '/tenant-manage/tenant-list' },
        { name: '员工管理', url: '/tenant-manage/tenant-list/staff-list' },
        { name: '部门管理', url: '' },
      ],
    },
  },
  {
    path: '/tenant-manage/list-view',
    component: ListView,
    meta: {
      keepAlive: true,
      tar: [
        { name: '企业管理', url: '' },
        { name: '列表视图', url: '/tenant-manage/list-view' },
      ],
    },
  },
  {
    path: '/tenant-manage/list-view/info',
    component: TenantDetail,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '列表视图', url: '/tenant-manage/list-view' },
        { name: '查看详情', url: '/tenant-manage/list-view/info' },
      ],
    },
    name: 'info',
  },
  {
    path: '/tenant-manage/building-manage',
    component: BuildingManage,
    meta: {
      keepAlive: true,
      tar: [
        { name: '企业管理', url: '' },
        { name: '楼层管理', url: '/tenant-manage/building-manage' },
      ],
    },
  },
  {
    path: '/tenant-manage/building-manage/info',
    component: TenantDetail,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '楼层管理', url: '/tenant-manage/building-manage' },
        { name: '查看详情', url: '/tenant-manage/building-manage/info' },
      ],
    },
    name: 'info',
  },
  {
    path: '/tenant-manage/user',
    component: UserList,
    meta: {
      keepAlive: true,
      tar: [
        { name: '企业管理', url: '' },
        { name: '用户列表', url: '/tenant-manage/user' },
      ],
    },
  },
  {
    path: '/tenant-manage/user/info',
    component: UserInfo,
    meta: {
      tar: [
        { name: '企业管理', url: '' },
        { name: '用户列表', url: '/tenant-manage/user' },
        { name: '查看详情', url: '/tenant-manage/user/info' },
      ],
    },
  },
]
