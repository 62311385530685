const VIPVisitor = () => import('@building/vuepage/order-manage/VIP-visitor.vue');
const VIPInfo = () => import('@building/vuepage/order-manage/VIP-info.vue');
const CardRenew = () => import('@building/vuepage/order-manage/card-renew.vue');
const ParkingPay = () => import('@building/vuepage/order-manage/parking-pay.vue');
const ParkingInfo = () => import('@building/vuepage/order-manage/parking-info.vue');
const ChargingPay = () => import('@building/vuepage/order-manage/charging-pay.vue');
const ChargingInfo = () => import('@building/vuepage/order-manage/charging-info.vue');
const FloorOrder = () => import('@building/vuepage/order-manage/floor-order.vue');
const FloorInfo = () => import('@building/vuepage/work-order-manage/property-info.vue');
const StarPay = () => import('@building/vuepage/order-manage/star-pay.vue');
const StarInfo = () => import('@building/vuepage/order-manage/star-info.vue');

export default [
  {
    path: '/order-manage/VIP-visitor',
    component: VIPVisitor,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '访客邀请', url: '/order-manage/VIP-visitor' },
      ],
    },
  },
  {
    path: '/order-manage/VIP-visitor/VIP-info',
    component: VIPInfo,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '访客邀请', url: '/order-manage/VIP-visitor' },
        { name: '查看详情', url: '/order-manage/VIP-visitor/VIP-info' },
      ],
    },
  },
  {
    path: '/order-manage/star-pay',
    component: StarPay,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '零星缴费', url: '/order-manage/star-pay' },
      ],
    },
  },
  {
    path: '/order-manage/card-renew',
    component: CardRenew,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '月卡续费', url: '/order-manage/card-renew' },
      ],
    },
  },
  {
    path: '/order-manage/parking-pay',
    component: ParkingPay,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '临停缴费', url: '/order-manage/parking-pay' },
      ],
    },
  },
  {
    path: '/order-manage/parking-pay/info',
    component: ParkingInfo,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '临停缴费', url: '/order-manage/parking-pay' },
        { name: '查看详情', url: '' },
      ],
    },
  },
  {
    path: '/order-manage/charging-pay',
    component: ChargingPay,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '充电缴费', url: '/order-manage/charging-pay' },
      ],
    },
  },
  {
    path: '/order-manage/charging-pay/info',
    component: ChargingInfo,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '充电缴费', url: '/order-manage/charging-pay' },
        { name: '查看详情', url: '' },
      ],
    },
  },
  {
    path: '/order-manage/star-pay/info',
    component: StarInfo,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '零星缴费', url: '/order-manage/star-pay' },
        { name: '查看详情' },
      ],
    },
  },
  {
    path: '/order-manage/floor-order',
    component: FloorOrder,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '工单打赏', url: '/order-manage/floor-order' },
      ],
    },
  },
  {
    path: '/order-manage/floor-order/floor-info',
    component: FloorInfo,
    meta: {
      tar: [
        { name: '订单管理', url: '' },
        { name: '工单打赏', url: '/order-manage/floor-order' },
        { name: '查看详情', url: '/order-manage/floor-order/floor-info' },
      ],
    },
  },
]
