const ContentManage = () => import('@building/vuepage/content-manage/content-manage.vue');
const MassagePush = () => import('@building/vuepage/content-manage/massage-push.vue');
const MoneySet = () => import('@building/vuepage/content-manage/money-set.vue');
const MoneyInfo = () => import('@building/vuepage/content-manage/money-info.vue');
const Warranty = () => import('@building/vuepage/content-manage/warranty-type.vue');
const MessageInfo = () => import('@building/vuepage/content-manage/messgae-info.vue');

export default [
  {
    path: '/content-manage/content-manage',
    component: ContentManage,
    meta: {
      tar: [
        { name: '内容管理', url: '' },
        { name: '规则说明', url: '/content-manage/content-manage' },
      ],
    },
  },
  {
    path: '/content-manage/massage-push',
    component: MassagePush,
    meta: {
      tar: [
        { name: '内容管理', url: '' },
        { name: '消息推送', url: '/content-manage/massage-push' },
      ],
    },
  },
  {
    path: '/content-manage/massage-push/list-info',
    component: MessageInfo,
    meta: {
      tar: [
        { name: '内容管理', url: '' },
        { name: '消息推送', url: '/content-manage/massage-push' },
        { name: '查看列表', url: '/content-manage/massage-push/list-info' },
      ],
    },
  },
  {
    path: '/content-manage/money-set',
    component: MoneySet,
    meta: {
      tar: [
        { name: '内容管理', url: '' },
        { name: '金额设置', url: '/content-manage/money-set' },
      ],
    },
  },
  {
    path: '/content-manage/money-set/info',
    component: MoneyInfo,
    meta: {
      tar: [
        { name: '内容管理', url: '' },
        { name: '金额设置', url: '/content-manage/money-set' },
        { name: '查看详情', url: '/content-manage/money-set/info' },
      ],
    },
  },
  {
    path: '/content-manage/warranty-type',
    component: Warranty,
    meta: {
      tar: [
        { name: '内容管理', url: '' },
        { name: '报修类型', url: '/content-manage/warranty-type' },
      ],
    },
  },
]
