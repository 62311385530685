const List = () => import('@building/vuepage/release/list.vue');
const Info = () => import('@building/vuepage/release/info.vue');
const Historys = () => import('@building/vuepage/release/history.vue');

export default [
  {
    path: '/release/list',
    component: List,
    meta: {
      tar: [
        { name: '放行管理', url: '' },
        { name: '物品放行', url: '/release/list' },
      ],
    },
  },
  {
    path: '/release/list/info',
    component: Info,
    meta: {
      tar: [
        { name: '放行管理', url: '' },
        { name: '物品放行', url: '/release/list' },
        { name: '查看详情', url: '/release/list/info' },
      ],
    },
  },
  {
    path: '/release/list/history',
    component: Historys,
    meta: {
      tar: [
        { name: '放行管理', url: '' },
        { name: '物品放行', url: '/release/list' },
        { name: '查询记录', url: '/release/list/history' },
      ],
    },
  },
]
