<template>
  <section class="main-container">
    <section class="container-left">
      <slot name="left"/>
    </section>
    <section
      class="container-right">
      <slot
        name="right"
      />
    </section>

  </section>
</template>

<script>
export default {
  name: 'Toolbar',
}
</script>

<style lang="scss" scoped>
  .main-container{
    min-height: 60px;
    position: relative;
    .container-right{
      position: absolute;
      top: 0;
      right:0;
      display: flex;
      button{
        margin-left: 2px;
      }
    }
    .container-left{
      display: flex;
    }
  }

</style>
