<template>
    <div>
        <el-upload
            action=""
            :http-request="onUploadImage"
            :limit="limit"
            :multiple="false"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :file-list="defaultImageArray"
            :on-preview="onImagePreview"
            :on-remove="onImageRemove"
            :on-exceed="onImageLimited"
            :class="{ 'show-upload': showUploadBtn }"
        >
            <!-- 如果已有一张图片则不显示上传按钮 -->
            <i class="el-icon-plus" />
        </el-upload>
        <el-dialog :visible.sync="imagePreviewDialog">
            <img width="100%" :src="previewImage" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import env from '@building/configs/environment';
import qiniu from '@building/mixins/qiniu';

// eslint-disable-next-line
export default {
    mixins: [qiniu],
    props: {
        value: {
            type: [String, Array],
            default: () => [],
        },
        limit: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            previewImage: '',
            imagePreviewDialog: false,
        };
    },
    computed: {
        defaultImageArray() {
            // Element UI 规定要传这样的
            let result = [];
            // 如果传进来的字符串
            if (this.limit === 1 && this.value) {
                result = [
                    {
                        name: '',
                        /* eslint-disable */
                        url: `${env.imgDomain}${this.value}`,
                    },
                ]
                return result
            }

            // 如果传进来的是数组
            if (this.value.length && this.limit > 1) {
                this.value.forEach((item) => {
                    result.push(
                        {
                            hash: item,
                            name: '',
                            url: `${env.imgDomain}${item}`,
                        },
                    )
                })
            } else {
                result = []
            }
            return result
        },
        showUploadBtn() {
            if (this.value.length <= this.limit) {
                return false
            }
            return true
        },
    },
    methods: {
        onImageLimited() {
            this.$message({
                message: `只能上传${this.limit}张`,
                type: 'info',
            })
        },
        beforeAvatarUpload({ size, type }) {
            const isImg = (type === 'image/png' || type === 'image/jpeg');
            const isLt2M = size / 1024 / 1024 < 5;
            console.log(size)
            if (!isImg) {
                this.$message.error('上传图片只能是jpg/png格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            return isLt2M && isImg;
        },
        onUploadImage(request) {
            // 上传流程使用混入
            this.onUploadImageMixins(request.file)
                .then(() => {
                    if (this.limit > 1) {
                        const imageArr = this.value
                        imageArr.push(this.hash)
                        this.$emit('input', imageArr)
                        return
                    }
                    console.log(this.hash);
                    this.$emit('input', this.hash)
                }).catch(() => {
                    this.$message.error('文件上传发生错误!');
                })
        },
        onImagePreview(image) {
            this.previewImage = image.url
            this.imagePreviewDialog = true
        },
        onImageRemove(file, fileList) {
            if (this.limit > 1) {
                const imageArr = fileList.map(item => item.url)
                this.$emit('input', imageArr)
                return
            }
            this.$emit('input', '')
        },
    },
}
</script>

<style lang="scss">
.show-upload {
    :deep(.el-upload) {
        display: none !important;
    }
}

// 上传模块动画移除
.el-upload-list__item {
  transition: none;
}
</style>
