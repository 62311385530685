const IdentityList = () => import('@building/vuepage/property-manage/identity-list.vue');
const Add = () => import('@building/vuepage/property-manage/add.vue');
const Info = () => import('@building/vuepage/property-manage/info.vue');
const BranchList = () => import('@building/vuepage/property-manage/branch-list.vue');
const Stanging = () => import('@building/vuepage/property-manage/stanging-list.vue');

export default [
  {
    path: '/property-manage/identity-list',
    component: IdentityList,
    meta: {
      tar: [
        { name: '物业员工管理', url: '' },
        { name: '员工列表', url: '/property-manage/identity-list' },
      ],
      keepAlive: true,
    },
  },
  {
    path: '/property-manage/identity-list/add',
    component: Add,
    meta: {
      tar: [
        { name: '物业员工管理', url: '' },
        { name: '员工列表', url: '/property-manage/identity-list' },
        { name: '新增', url: '' },
      ],
    },
  },
  {
    path: '/property-manage/identity-list/edit',
    component: Add,
    meta: {
      tar: [
        { name: '物业员工管理', url: '' },
        { name: '员工列表', url: '/property-manage/identity-list' },
        { name: '编辑', url: '' },
      ],
    },
  },
  {
    path: '/property-manage/identity-list/stanging',
    component: Stanging,
    meta: {
      tar: [
        { name: '物业员工管理', url: '' },
        { name: '员工列表', url: '/property-manage/identity-list' },
        { name: '身份列表', url: '' },
      ],
    },
  },
  {
    path: '/property-manage/identity-list/info',
    component: Info,
    meta: {
      tar: [
        { name: '物业员工管理', url: '' },
        { name: '员工列表', url: '/property-manage/identity-list' },
        { name: '详情', url: '' },
      ],
    },
    name: 'info',
  },
  {
    path: '/property-manage/identity-list/branch-list',
    component: BranchList,
    meta: {
      tar: [
        { name: '物业员工管理', url: '' },
        { name: '员工列表', url: '/property-manage/identity-list' },
        { name: '部门管理', url: '' },
      ],
    },
  },
]
