<template>
    <section class="container">
        <el-table
            ref="table"
            :data="datasource.data"
            border
            row-key="uuid"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @sort-change="onSortChange"
            @row-click="rowClick"
            @cell-click="cellClick"
            @select-all="selectAll"
        >
            <!-- 勾选 -->
            <el-table-column
                v-if="_config.bulkDelete"
                type="selection"
                fixed="left"
                :selectable="selectable"
                width="55"
            ></el-table-column>

            <el-table-column
                v-if="_config.index"
                fixed
                type="index"
                width="50"
                :index="handlerSerial"
                label="编号"
                align="center"
            ></el-table-column>

            <el-table-column
                v-for="(item, index) in _config.fields"
                :key="item.prop + index"
                align="center"
                v-bind="item"
            ></el-table-column>

            <!-- 数据处理插槽 -->
            <slot name="filter" />

            <!-- 操作栏插槽   -->
            <slot name="action" />
        </el-table>

        <div class="block">
            <div>
                <span
                    style="margin-left: 20px; color: #606266"
                    class="demonstration"
                    >共{{ datasource.total || datasource.data.length }}条</span
                >
                <el-pagination
                    v-if="_config.pagination"
                    ref="pagination"
                    :page-size="_config.size"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page="page"
                    background
                    layout="prev, pager, next,sizes"
                    :total="datasource.total"
                    @size-change="sizeChange"
                    @current-change="handlerChange"
                />
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable vue/no-reserved-keys,no-underscore-dangle */

import object from '@building/jslib/object';

export default {
    props: {
        config: {
            type: Object,
            default: () => ({
                pagination: true,
                size: 10,
                index: true,
                isBanChekbox: true,
                fields: [],
            }),
            // 用于校验必传配置
            validator: value => object.isComplete(value, ['fields']),
        },
        datasource: {
            type: Object,
            default: () => ({
                total: 0,
                data: [],
            }),
            required: true,
            // 用于校验必传配置
            validator: value => object.isComplete(value, ['total', 'data']),
        },
        size: {
            type: Number,
            default: () => 1,
        },
    },
    data() {
        return {
            // _config: {},
            page: 1,
            multipleSelection: [],
        };
    },

    computed: {
        _config() {
            if (
                object.isComplete(this.config, ['pagination', 'size', 'fields'])
            ) {
                return this.config;
            }
            return Object.assign(
                {},
                {
                    pagination: true,
                    size: 10,
                    fields: [
                        {
                            prop: 'name',
                            label: '名称',
                        },
                    ],
                },
                this.config
            );
        },
    },
    //  监控选中的数组
    watch: {
        multipleSelection() {
            this.$emit('selectVal', this.multipleSelection);
        },
        datasource: {
            handler() {
                // 数据变化
                this.$nextTick(() => {
                    // console.log('doLayout');
                    this.$refs.table.doLayout();
                });
            },
            immediate: true,
        },
        size: {
            handler(val) {
                this.page = val;
            },
            immediate: true,
        },
    },
    created() {
        // if (object.isComplete(this.config, ['pagination', 'size', 'fields'])) {
        //   this._config = this.config;
        //   return;
        // }
        // this._config = Object.assign(
        //   {},
        //   {
        //     pagination: true,
        //     size: 10,
        //     fields: [
        //       {
        //         prop: 'name',
        //         label: '名称',
        //       },
        //     ],
        //   },
        //   this.config,
        // );
    },

    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val.map(item => {
                const li = item;
                if (li.uuid) {
                    return li.uuid;
                }
                return li.id;
            });
        },
        handlerChange(val) {
            this.page = val;
            this.$emit('change-page', {
                element: this.$refs.pagination,
                page: val,
            });
        },
        sizeChange(val) {
            this.$emit('size-change', {
                element: this.$refs.pagination,
                page: val,
            });
            this._config.size = val;
        },
        handlerSerial(index) {
            const { size } = this._config;
            const { page } = this;
            return (page - 1) * size + (index + 1);
        },
        // 排序
        onSortChange(item) {
            this.$emit('sort-change', item);
        },
        // 点击选择全部
        selectAll(val) {
            console.log(val);
            this.$emit('select-all', 1);
        },
        // 点击当前行
        rowClick(val, column, event) {
            this.$emit('rowLink', val, column, event);
        },
        cellClick(val, column, cell) {
            this.$emit('cellClick', val, column, cell);
        },
        selectable(row) {
            // eslint-disable-next-line
            if (row.wrdOperation != 1 &&row.wrdOperation || row.monthType == 1&&row.monthType) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    height: 100%;

    // .el-table {
    //     // margin-bottom: 60px;
    // }

    .block {
        margin: 30px 0px 0px 30px;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0px;
        }
    }
}
</style>
