<template>
    <el-autocomplete
        v-model="homeName"
        popper-class="my-autocomplete"
        placeholder="请选择企业"
        :fetch-suggestions="querySearch"
        @select="handleSelect"
    >
        <i slot="suffix" class="el-icon-edit el-input__icon" />
        <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
        </template>
    </el-autocomplete>
</template>

<script>
import { getCompanyList } from '../api/company';

export default {
    props: {
        value: {
            type: String,
            default: () => '',
        },
        name: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            homeName: '',
            homeList: [], // 数据
            id: '',
            status: '',
            queryData: {
                pageIndex: 1,
                pageSize: 300,
                keyword: '',
            },
        };
    },
    computed: {},
    created() {
        this.network().homeList();
    },
    methods: {
        handleSelect(item) {
            this.homeName = item.name;
            this.id = item.uuid;
            this.$emit('select', this.id, this.homeName);
        },
        /* eslint-disable */
        querySearch(queryString, cb) {
            var restaurants = this.homeList;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return logisticsCompany => (logisticsCompany.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        },
        network() {
            return {
                homeList: async () => {
                    const { data, status } = await getCompanyList(this.queryData)
                    if (status !== 200) return
                    this.homeList = data.list
                },
            }
        },
    },
    watch: {
        //  监控 homeName 如果为空   就将商品的option清空
        homeName(val) {
            if (val === '') {
                this.$emit('change', 1)
            }
        },
        // 编辑时传入的参数
        name(val) {
            if (val) {
                this.homeName = val
                this.$emit('change')
            }
        },
        // 重置商家
        value(val) {
            if (!val) {
                this.homeName = ''
                this.id = ''
                return
            }
            this.id = val
        }
    },
}
</script>

<style scoped></style>
